import * as React from "react";

// components
import DefaultLayoutComponent from "../components/layouts/DefaultLayoutComponent";
import ImageComponent from "../components/ImageComponent";
import {GatsbySeo} from "gatsby-plugin-next-seo";
import {Link} from "gatsby";

const AboutPage = () => {

    return (
        <DefaultLayoutComponent activeCategory="About">
            <GatsbySeo
                title='La Compta de Papa'
                description='Bienvenue sur le site de la Compta de Papa. Retrouvez une description de notre activité en ligne et contactez nous pour plus de détails.'
                canonical='https://lacomptadepapa.fr/a-propos'
                openGraph={{
                    url: 'https://lacomptadepapa.fr/a-propos',
                    title: 'La Compta de Papa',
                    description: 'Bienvenue sur le site de la Compta de Papa. Retrouvez une description de notre activité en ligne et contactez nous pour plus de détails.',
                }}
            />
            <main className={"pt-5 mt-lg-4 mt-5"}>
                <div className="mt-3 mt-lg-0 slider-area hero-bg-color hero-height2">
                    <div className="slider-active">
                        <div className="single-slider">
                            <div className="slider-cap-wrapper">
                                <div className="hero-caption hero-caption2">
                                    <h2 className={"text-center text-sm-start mb-0"} data-animation="fadeInUp" data-delay=".2s">A propos de nous</h2>
                                </div>
                                <div className="hero-img hero-img2 position-relative" data-animation="fadeInRight" data-transition-duration="5s">
                                    <ImageComponent imageName={"hero3.jpg"} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="testimonial top-padding bottom-padding">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-6 col-lg-6">
                                <ImageComponent imageName={"child.jpg"} alt={"Nous analysons votre activité pour vous aider du mieux possible"}/>
                            </div>
                            <div className="offset-xl-1 col-xl-5 col-lg-6 col-md-9">
                                <div className="testimonial-area">
                                    <div className="section-tittle mb-50">
                                        <h2>L'histoire de papa</h2>
                                    </div>
                                    <div className="h1-testimonial-active">
                                        <div className="single-testimonial">
                                            <div className="testimonial-caption ">
                                                <div className="testimonial-top-cap">
                                                    <p>Après un long brainstorming avec ma femme pour savoir comment allait se nommer ce nouveau projet, mon fils se mêla à la discussion pour dire tout simplement : "Et si le cabinet de papa on l'appelait la compta de papa !"</p>
                                                </div>
                                                <div className="testimonial-founder d-flex align-items-center">
                                                    <div className="founder-text">
                                                        <span>Damien Commarmond</span>
                                                        <p>La Compta de Papa</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="brand-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-8 col-lg-8 col-md-10">
                                <div className="section-tittle section-tittle2 text-center d-flex justify-content-around">
                                    <h2 className={'m-0'}>N'hésitez pas, vous pouvez <Link to={"/nous-contacter"} className={'text-decoration-underline text-warning'}>nous contacter</Link> !</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="pricing-card-area bg-white fix">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="offset-xl-1 col-xl-5 col-lg-6 col-md-6">
                                <div className="section-tittle mb-15">
                                    <h2>Une qualité de service <strong>garantie</strong></h2>
                                    <p>Pour une prise en charge optimale nous nous engageons à nous former régulièrement afin d’être continuellement compétent en fonction des évolutions des lois de finance. La réactivité est également un facteur essentiel dans notre démarche de qualité de service. Nous avons pleinement conscience que lorsque vous avez une question ou des doutes vous aimez avoir des réponses rapides pour pouvoir continuer d’avancer dans vos projets.</p>
                                </div>
                            </div>
                            <div className="offset-lg-1 col-xl-4 col-lg-5 col-md-6 col-sm-8">
                                <div className="card-wrapper">
                                    <ImageComponent imageName={"gallery3.jpg"} alt={'Comptabilité et fiscalité'}/>
                                </div>
                            </div>
                            <blockquote className="generic-blockquote">
                                “Après une quinzaine d'années d'expérience en tant que chef de mission dans différents cabinets de taille régionale et nationale, et l'obtention du Diplôme d'Expert Comptable, l'envie d'entreprendre s'est ressentie.
                                Le nom du cabinet représente bien mes valeurs et ma vision de la profession. Être proche de mes clients, accessible, réactif.
                                <br/>
                                Non l’Expert Comptable n’est pas le médecin de l’entreprise comme beaucoup le laisse penser surtout en période de pandémie. Personnellement je vais voir mon médecin lorsque je suis malade et pour moi une entreprise à besoin de son Expert Comptable tout au long de la vie de la société, dans les bons comme dans les moments plus difficiles. Ma perception du métier se qualifierait plus comme un coach.”
                            </blockquote>
                        </div>
                    </div>
                </section>
                <section className="wantToWork-area w-padding2">
                    <div className="container border-bottoms pb-50">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-xl-8 col-lg-9 col-md-8">
                                <div className="wants-wrapper">
                                    <div className="wantToWork-caption wantToWork-caption2">
                                        <h2>Des envies de création ?</h2>
                                        <p>Nous vous proposons de vous aider et de vous accompagner de l’étincelle de votre projet jusqu’à son aboutissement.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-4 d-flex d-sm-block justify-content-center">
                                <Link to="/nous-contacter" className="btn_1 wantToWork-btn brand-btn f-right">Nous contacter</Link>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </DefaultLayoutComponent>
    )
};

export default AboutPage;
